// DEFINITION DES PARAMETRES GLOBAUX 
// A IMPORTER DANS LES FICHIERS QUI EN ONT BESOIN

// Exemple :
// import {ConfigVars} from "../config-app";
//
// console.log('Appel API : ' + ConfigVars.URL_API + 'laveries' );

// PROD

export const ConfigVars = {
    URL_API: 'https://api.cogedia.com/', 
    //URL_RETOURMERCANET_RECHARGE: 'https://beta.proxiwash.com/retour'
    URL_RETOURMERCANET_RECHARGE: 'https://sorenzo2.proxiwash.com/retour',
    URL_RETOURMERCANET_PAIEMENTCB: 'https://sorenzo2.proxiwash.com/retourpaymentcb'
  }


// POUR DEV
/*
export const ConfigVars = {
    URL_API: 'https://dev.proxiwash.com/api/', 
    URL_RETOURMERCANET_RECHARGE: 'https://sorenzo2.proxiwash.com/retour',
    URL_RETOURMERCANET_PAIEMENTCB: 'https://sorenzo2.proxiwash.com/retourpaymentcb'
  }
*/


  export const ModesPaiements = {
    MODEPAIEMENT_ESPECES: 100001000001001,
    MODEPAIEMENT_PIECES: 100001000001007,
    MODEPAIEMENT_BILLETS: 100001000001006,
    MODEPAIEMENT_CARTEBANCAIRE: 100001000001002,
    MODEPAIEMENT_COMPTE: 100001000001005,
    MODEPAIEMENT_NAYAX: 300003000102006,
    MODEPAIEMENT_IZLY: 300003000102007,
    MODEPAIEMENT_CARTEBANCAIREINTERNET: 100001000001009,
  }

  // Etats Machine
  export const Etats = {
    ETAT_DISPONIBLE: 100001000001007,
    ETAT_HS: 100001000001008,
    ETAT_OCCUPE: 100001000001009,
    ETAT_TERMINE: 100001000001014,
    ETAT_ERREUR: 300003000050001,
    ETAT_HSMANUEL: 300003000102019,
    ETAT_REGLE: 100001000001075,
    ETAT_ARRETE: 300003000102018,

    ETAT_VALIDE: 100001000001102,
    ETAT_ANNULATION: 100001000001016,
    ETAT_COURANT: 100001000001001,
    ETAT_ATTENTEVALIDATION: 100001000001037
  }
  
  // Etats utilisés pour le paiement CB en ligne ( Ex : Mercanet )
  export const EtatsReglementCB = {
    ETAT_REGLEMENTCB_REGLE: 100001000001075,
    ETAT_REGLEMENTCB_REFUSE: 17,
    ETAT_REGLEMENTCB_ANNULATION: 100001000001016,
    ETAT_REGLEMENTCB_ATTENTEVALIDATIONBANQUE: 100001000001038
  }

  // Etats utilisés pour l'ordre de reglement par compte client
  export const EtatsOrdreReglementCompteClient = {
    ETAT_ORDREREGLEMENTCOMPTE_COURANT: 100001000001001, // ( ordre envoyé sur le serveur )
    ETAT_ORDREREGLEMENTCOMPTE_ACTIVITE: 100001000001015, // ( ordre acquitté par la CPI / en cours de traitement )
    ETAT_ORDREREGLEMENTCOMPTE_ERREUR: 300003000050001,    // retour = erreur
    ETAT_ORDREREGLEMENTCOMPTE_ANNULATION: 100001000001016,  // ordre annulé par le tdb par ex
    ETAT_ORDREREGLEMENTCOMPTE_TERMINE: 100001000001014,
    ETAT_ORDREREGLEMENTCOMPTE_VALIDE: 100001000001102,
    ETAT_ORDREREGLEMENTCOMPTE_ATTENTEVALIDATION: 100001000001037,    
    ETAT_ORDREREGLEMENTCOMPTE_OCCUPE: 100001000001009,
  }

  export const VarsIHM = {
    LISTEMACHINES_AFFICHER_BOUTONQRCODE: false,
    PAYMENT_AFFICHER_BOUTON_COMPTECLIENT: true,
    PAYMENT_AFFICHER_BOUTON_CB: true
  }
