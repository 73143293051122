<template>
<div id="machine" class="machine">
    <div class="machine-content fadeInRight">
        <div class="machine-header">
            <!--<router-link class="btn-retour" v-bind:to="routerLinkRetour" @click="select = false">
                <img src="@/assets/icon_back.svg" alt="Retour">
            </router-link>-->
            <div class="btn-retour" @click="actionRetour(); select = false">
                <img src="@/assets/icon_back.svg" alt="Retour">
            </div>
            <p class="subtitle">{{ infoMachine.sitnom }}</p>
            <h2> N°{{ infoMachine.numero }}</h2>
            <h2 class="title">{{ infoMachine.nom }}</h2>
            <h2 v-if="etatComponent > 0 && selectedTarifs != null" class="title">{{ selectedTarifs.ref_nom }}</h2>
            <h2 v-if="etatComponent > 0 && selectedTarifs != null  && selectedTarifs != '' && selectedTarifs.prix > 0" class="title">{{ selectedTarifs.prix }} &euro;</h2>
        </div>
    </div>

    <div v-if="messageErreur != ''">
        {{ messageErreur }}
    </div>

    <div class="machine-container">
        <Program v-if="etatComponent == 0" :typemachineDefaut="typemachine" :tarifsMachine="tarifsMachine" :infoReservation="infoReservation" :messagesprog="messagesprog" @programSelected="setSelectedProgram" @reservationSelected="setReservationSelected"/>
        <PaymentMethod v-if="etatComponent == 1" :modepaiementsActifsLaverie="paiementsData" :infoMachine="infoMachine" :typemachineDefaut="typemachine" :id="id" :tarifs="selectedTarifs" @setOrder="setMachineOrder"/>
        <Order v-if="etatComponent == 2" :machineOrder="order" :id="id" @machineOccupe="setMachineStatus"/>
        <MachineStatus v-if="etatComponent == 3" :id="id" :infoMachine="infoMachine"/>
        <OrderReservation v-if="etatComponent == 4" :machineOrder="order" :id="id" />
    </div>
</div>
</template>

<script>
import Program from '@/components/Program.vue'
import PaymentMethod from '@/components/PaymentMethod.vue'
import Order from '@/components/Order.vue'
import OrderReservation from '@/components/OrderReservation.vue'
import axios from 'axios';
import MachineStatus from '../components/MachineStatus.vue';
import {ConfigVars} from "../config-app";
import {Etats} from "../config-app";

const EtapeIHM = {
    ETAPE_0_CHOIX_SELECTION: 0,
    ETAPE_1_CHOIX_MODEPAIEMENT: 1,
    ETAPE_2_DEMARRAGEMACHINE: 2,
    ETAPE_3_MACHINEDEMARREE: 3,
    ETAPE_4_SUIVIRESERVATIONMACHINE: 4,
  }

export default {
    name: 'Machine',
    props: {
        // Id de la machine
        id: String,

        // Modes de paiement dispos
        paiements: Object,

        prop_order: String,
        prop_etatComponent: {
            type: Number,
            default: 0
        },
        prop_montant: {
            type: Number,
            default: 0
        },
        prop_ref_nom: String,


        // Tableau des modes de paiement de la laverie au format JSON
        paiementsDataStr: {
            type: String,
            default: ''
        },

        // Objet reservation associée a la machine
        //o_reservation


    },
    components: {
        Program,
        PaymentMethod,
        Order,
        OrderReservation,
        MachineStatus,
    },
    data: function() {
        return {
            message: '',
            paymentMethodType: '',
            infoMachine: {},
            infoReservation: {},
            tarifsMachine: {},
            etatComponent: EtapeIHM.ETAPE_0_CHOIX_SELECTION,
            intervalMachineEtat: null,
            selectedTarifs: {},
            order: '',
            typemachine: '',
            messagesprog: [],

            // Tableau des modes de paiement de la laverie
            paiementsData:[],
            messageErreur: ''
        }
    },
    /*computed: {
        routerLinkRetour: function () {
            var afficherLaveLingeDefaut = 'true';
            if ( this.infoMachine != null && this.infoMachine.type == '3')
                afficherLaveLingeDefaut = 'false';
            return '/laverie/' + this.infoMachine.sitid + '/true/' + afficherLaveLingeDefaut;
        }
    },*/
    mounted: function()  {

        


        /*if ( this.$route.params.modepaiements != null && this.$route.params.modepaiements.length > 0)
        {
            console.log('Modes : ' , this.$route.params.modepaiements);
            for (var i = 0; i < this.$route.params.modepaiements.length; i++) {
                //console.log('Mode : ' + this.$route.params.paiementCustom[i].id + '-' +  this.$route.params.paiementCustom[i].nom );
                console.log('Mode : ' , this.$route.params.modepaiements[i] );
            }
        }
        else
            console.log('Aucun modes');
        

        if ( this.paiementCustom != null &&  this.paiementCustom.length > 0)
        {
            console.log('paiementCustoms : ' , this.paiementCustom);
            for (var k = 0; k < this.paiementCustom.length; k++) {
                //console.log('Mode : ' + this.paiementCustom[k].id + '-' +  this.paiementCustom[k].nom );
                console.log('paiementCustom : ' , this.paiementCustom[k] );
            }
        }
        else
            console.log('Aucun paiementCustom');
        */
        //this.paiementsData2 = this.paiementsData; 

        
        
        

        if ( this.prop_etatComponent > 0)
            this.etatComponent = Number(this.prop_etatComponent);
        if ( this.prop_montant > 0)
            this.selectedTarifs = {ref: 1,ref_nom: this.prop_ref_nom, prix: Number(this.prop_montant),programSelected: true};
        if ( this.prop_order != '')
            this.order = this.prop_order;            

        this.messagesprog = [];
        this.getInfoMachine();

        
    },
    methods: {
        // Traitement du clic sur le bouton Retour
        actionRetour: function() {
            switch ( this.etatComponent)
            {
                case EtapeIHM.ETAPE_0_CHOIX_SELECTION:  // Liste des selections
                case EtapeIHM.ETAPE_3_MACHINEDEMARREE:  // Fin du traitement de paiement / demarrage
                case EtapeIHM.ETAPE_4_SUIVIRESERVATIONMACHINE: 

                    // Retour a la liste des machines
                    var afficherLaveLingeDefaut = 'true';
                    if ( this.infoMachine != null && this.infoMachine.type == '3')
                        afficherLaveLingeDefaut = 'false';
                    this.$router.push('/laverie/' + this.infoMachine.sitcle + '/true/' + afficherLaveLingeDefaut); 
                    break;

                // Choix du mode de paiement
                case EtapeIHM.ETAPE_1_CHOIX_MODEPAIEMENT:
                    // Retour a la liste des selections
                    this.etatComponent = 0;
                    break;

                // Traitement de l'ordre de paiement / demarrage
                case EtapeIHM.ETAPE_2_DEMARRAGEMACHINE:
                    // On fait quoi ?
                    break;

            }
        },

        setSelectedProgram: function(tarifs) {
            this.etatComponent = EtapeIHM.ETAPE_1_CHOIX_MODEPAIEMENT;   

            console.log('tariffff', tarifs);

            this.selectedTarifs = tarifs;
        },

        setReservationSelected: function(args) {
            //this.etatComponent = EtapeIHM.ETAPE_1_CHOIX_MODEPAIEMENT;   
            console.log('setReservationSelected', args);

            console.log("RESERVATION DE LA MACHINE ... " , this.infoMachine)

            this.messageErreur = '';

            axios({
                method: 'get',
                url: ConfigVars.URL_API + 'laveries/' + this.infoMachine.sitcle + '/ping',
                data: {},
            })
            .then((response) => {
                console.log('success Ping : ', response)  ;

                if ( response.data.data.ping.pingok == true )
                {
                    console.log('Ping ok ... Reservation ... ' + this.id )  ;
                    
                            // Envoie a la centrale la demande d'ordre de reservation en compte
                            // Retour = demandeordre pour surveiller l'evolution de l'ordre
                            axios({
                                method: 'post',
                                url: ConfigVars.URL_API + 'machines/' + this.id + '/reserver',
                                data: {},
                                headers: {
                                    Authorization: 'Bearer ' + this.$store.state.user.token
                                }
                            })
                            .then((response) => {
                                console.log('success reserver', response)  

                                //this.$emit('setOrder',response.data.data.ordre);

                                this.etatComponent = EtapeIHM.ETAPE_4_SUIVIRESERVATIONMACHINE;
                                this.order = response.data.data.ordre;

                            })
                            .catch((response) => {
                                console.log('error reserver',response);
                            });
                }
                else
                {
                    console.log('Ping NOK !! ')  ;
                    this.messageErreur = 'LAVERIE PAS CONNECTEE !! MERCI DE REESSAYER PLUS TARD';
                }

            })
            .catch((response) => {
                console.log('error',response);
                this.messageErreur = 'ERREUR : IMPOSSIBLE DE RECUPERER L\'ETAT DE LA LAVERIE !! MERCI DE REESSAYER PLUS TARD';
            });



        },

        setPaymentMethod: function(paymentMethod) {
            this.paymentMethodType = paymentMethod.paymentMethodSelected;
        },
        setMachineOrder: function(order) {
            this.etatComponent = EtapeIHM.ETAPE_2_DEMARRAGEMACHINE;
            this.order = order
        },
        setMachineStatus: function() {
            console.log('etatComponent MACHINE DEMARREE');
            this.etatComponent = EtapeIHM.ETAPE_3_MACHINEDEMARREE;
        },
        getInfoMachine: function() {
            axios.get(ConfigVars.URL_API + 'scanner', {params: {barcode: this.id}})
            .then((response) => {
                this.infoMachine = response.data.data.machine.attributs;
                this.infoReservation= response.data.data.machine.reservationmachine;

                console.log('getInfoMachine success - typemachine set ...', response);
                this.typemachine = this.infoMachine.type; 
                console.log('getInfoMachine - typemachine set = ' + this.typemachine + ' ok');

                console.log('infoMachine ...');
                console.log('infoMachine : ', this.infoMachine);
                console.log('infoReservation : ', this.infoReservation);

                if ( this.infoMachine.etat != null && this.infoMachine.etat != '' && !isNaN( this.infoMachine.etat))
                {
                    switch (Number(this.infoMachine.etat) )
                    {
                        case Etats.ETAT_DISPONIBLE: // DISPO
                            this.tarifsMachine = response.data.data.machine.tarifs.items;
                            if ( this.tarifsMachine.length == 0)
                                this.messagesprog.push('PAS DE SELECTION TROUVEE SUR LA MACHINE');
                            break;

                        case Etats.ETAT_REGLE: // REGLE
                            this.messagesprog.push('LA MACHINE EST REGLEE. VOUS POUVEZ LA DEMARRER EN CLIQUANT SUR SON BOUTON "START"');
                            break;

                        case Etats.ETAT_OCCUPE: // OCCUPE
                            this.messagesprog.push('LA MACHINE EST OCCUPEE');
                            if ( this.infoMachine.dureeerestante != '0')
                                this.messagesprog.push('TEMPS RESTANT : ' + this.infoMachine.dureeerestante);
                            break;

                        case Etats.ETAT_TERMINE: // TERMINE
                            this.messagesprog.push('LA MACHINE EST TERMINEE. VOUS POUVEZ RETIRER VOTRE LINGE');
                            break;

                        default:
                            this.messagesprog.push('LA MACHINE EST ' + this.infoMachine.etanom);
                            break;
                    }
                }
                else
                    this.messagesprog.push('LA MACHINE N\'EST PAS DISPONIBLE');

                if ( this.infoMachine.type == '2')
                    this.colorBlue();
                else if ( this.infoMachine.type == '3')
                    this.colorOrange();
                else
                    this.colorBlue();


                this.getModePaiementActif();


            })
            .catch((response) => {
                console.log('error',response);
            });
        },
        getModePaiementActif: function() {
            if ( this.paiementsDataStr != '')
                this.paiementsData = JSON.parse(this.paiementsDataStr);
            else
            {
                console.log('pas de mode de paiement recu ');

                axios.get(ConfigVars.URL_API + 'laveries/' + this.infoMachine.sitcle + '/paiements',)
                .then((response) => {
                    console.log('success paiements',response);
                    // Stocker le tableau des modes de paiement de la laverie
                    this.paiementsData = response.data.data.paiements.items;
                })
                .catch((response) => {
                    console.log('error get laverie paiements',response);
                });
            }

            if ( this.paiementsData != null && this.paiementsData.length > 0)
            {
                console.log('Modes paiementsData' , this.paiementsData);
                for (var j = 0; j < this.paiementsData.length; j++) {
                    //console.log('Mode paiementsData : ' + this.paiementsData[j].id + '-' +  this.paiementsData[j].nom );
                    console.log('Mode : ' , this.paiementsData[j] );
                }
            }
            else
                console.log('Aucun modes Data');
        },

        colorOrange() {
            var machine = document.getElementById('machine');
            machine.style.backgroundColor = "#FF8C7D";
        },
        colorBlue() {
            var machine = document.getElementById('machine');
            machine.style.backgroundColor = "#25B4CC";
        }
    }
}
</script>

<style scoped lang="scss">

.machine {
    padding: 2.4rem 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    color: #FFF;

    background-color: var(--primary);

    border-radius: 20px 20px 0 0;

    transition-duration: 0.2s;
    
    & .machine-container {
        margin-top: 50px;
    }


    & .machine-content {
        width: 100%;
        max-width: 800px;
        position: relative;

        & .machine-header {
            text-align: center;

            & .subtitle {
                margin-bottom: 15px;
                letter-spacing: 2.1px;
                font-size: 0.875rem;
            }

            & .title {
                font-size: 1.5625rem;
                font-weight: 800;
            }

            & .btn-retour {
                position: absolute;
                top: 65px;
                left: 0.5rem;
                cursor: pointer;
            }

            & .btn-info {
                position: absolute;
                top: 36px;
                right: 0.5rem;
            }
        }
    }
}

@media only screen and (min-width: 950px) {
    .machine {
        padding: 2.4rem 2rem;
        border-radius: 0;
    }
}

.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid var(--primary); /* Blue */
  border-radius: 50%;
  width: 32px;
  height: 32px;
  animation: spin 2s linear infinite;
}

.loader.success {
    animation:  none;
    background-color: lawngreen;
    border: none;
    
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

</style>