<template>
  <NavMenu/>
  <router-view class="content"/>
</template>

<script>

import NavMenu from '@/components/NavMenuTest.vue'

export default {
  name: 'App',
  components: {
    NavMenu,
  },
  created: function() {
    
    this.$store.dispatch('loginLocal');
    
  },
}
</script>

<style>
:root {
  --primary: #077C9A;
  --secondary: #087D9C;
  --accent: #FF8C7D;
  --background: #25B4CC;
  --black_text: #242829CC;

  --mobile-s: 320px;
  --mobile-m: 375px;
  --mobile-l: 425px;
  --tablet: 768px;
  --laptop: 1024px;
  --desltop: 1440px;
}

* {
  box-sizing: border-box;
}

html, body {
  font-size: 1rem;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

ul, ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: initial;
}

p {
  margin: 0;
  line-height: 1rem;
}
/*cite
button {
  cursor: pointer;
  text-decoration: none;
  border: none;
  background: none;
}
*/

#app {
  font-family: 'Nunito', Arial, sans-serif;
}

#app .router-link-exact-active {
      color: var(--accent);
}


.content {
  overflow: hidden;
  min-height: calc(100vh - 60px);
  margin-top: 60px;
  position: relative;
}

@media only screen and (min-width: 1200px) {
  .content {
    margin-top: 110px;
    min-height: calc(100vh - 110px);
  }
}

/*
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
*/
</style>
