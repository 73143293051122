import axios from 'axios';
import { createStore } from 'vuex'
import router from "../router/index.js"
import {ConfigVars} from "../config-app";

const Axios = axios.create({
  baseURL: ConfigVars.URL_API
});



const mutations = {
  SET_STATUT: (state, status) => {
    state.status = status;
  },
  LOG_USER: (state,token) => {
    state.user.token = token;
  },

  SET_TOKEN: (state,token) => {
    state.user.token = token;
  },
  
  SET_USER_INFO: (state,userInfos) => {
    state.user.data = userInfos;
  },

  SET_REMEMBER: (state,remember) => {
    state.remember = remember;
  },

  DELETE_USER_INFO: (state) => {
    state.user = {};
    state.user.token = {};
  },
  SET_CURRENT_TRANSACTION: (state,transaction) => {
    state.currentTransaction = transaction;
  },
  DELETE_CURRENT_TRANSACTION: (state) => {
    state.currentTransaction = {};
  }
  
}

const actions = {
  setCurentTransaction:({commit}, currentTransaction) => {
    commit('SET_CURRENT_TRANSACTION', currentTransaction);
    localStorage.setItem('currentTransation', JSON.stringify(currentTransaction));
  },
  deleteCurentTransaction:({commit}) => {
    commit('DELETE_CURRENT_TRANSACTION');
    localStorage.removeItem('currentTransation');
  },
  login: ({commit}, userInfos) => {
    commit('SET_STATUT', 'loading');

    if(userInfos.rememberme == 1) {
      commit('SET_REMEMBER', true);
      localStorage.setItem('remember', JSON.stringify(true));
    } else {
      commit('SET_REMEMBER', false);
      localStorage.setItem('remember', JSON.stringify(false));
    }
    //return new Promise((resolve, reject) => {
    return new Promise((resolve) => {

      console.log('Appel de login ...')

      Axios.post('/login',userInfos)
      .then((response) => {
        resolve(response);
        commit('SET_STATUT', 'tokenLogin');
        commit('LOG_USER', response.data.data.token);
        localStorage.setItem('token', JSON.stringify(response.data.data.token));
        
        Axios.get('/utilisateurs/' + userInfos.username, {params: {},headers: {'Authorization': 'Bearer ' + response.data.data.token}})
        .then((response) => {
          commit('SET_STATUT', 'loggedIn');
          commit('SET_USER_INFO', response.data.data);
          localStorage.setItem('user', JSON.stringify(response.data.data));

          if(userInfos.machineid == null || userInfos.machineid == '' || userInfos.machineid == '1' )
            router.push('/profile');
          else
            router.push('/machine/' + userInfos.machineid);

        })
        .catch((response) => {
          console.log('error login',response);
        });
      })
      .catch((error) => {
        console.log('erreur login', error);
        //reject(error);
        console.log('routage');
        router.push({ name: 'Login', params: { 
          errorLog: 'Identifiant ou mot de passe incorrect'
         }
        })
        console.log('routage ok');
        commit('SET_STATUT', 'logout');
        console.log('commit ok');
      });
    });
  },
  getUser: ({commit},username) => {
    var token = JSON.parse(localStorage.getItem('token'));
    console.log('getUser', username)
    console.log('getUser', username)
    Axios.get('/utilisateurs/' + username, {params: {},headers: {'Authorization': 'Bearer ' + token}})
        .then((response) => {
          commit('SET_STATUT', 'loggedIn');
          commit('SET_USER_INFO', response.data.data);
          localStorage.setItem('user', JSON.stringify(response.data.data));
        })
        .catch((response) => {
          console.log('error',response);
        });
  },

  loginLocal: ({commit}) => {
    var token = JSON.parse(localStorage.getItem('token'));
    var user = JSON.parse(localStorage.getItem('user'));
    var remember = JSON.parse(localStorage.getItem('remember'));

    if(remember == true) {
      if(user != null)
      {
        commit('SET_USER_INFO', user);
        commit('SET_TOKEN', token);
        commit('SET_STATUT', 'loggedIn');
      }
      else {
        commit('SET_STATUT', 'logout');
      }
    }else {
      commit('SET_STATUT', 'logout');
    }

    
  },
  logout: ({commit},rememberme) => {
    commit('SET_STATUT', 'logout');
    if(rememberme == false) {
      commit('DELETE_USER_INFO');
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      localStorage.removeItem('remember');
    }
  },
}

const modules = {

}

export default createStore({
  state: {
    status: 'logout',
    currentTransaction: {},
    remember: false,
    user: {
      token: '',
      data: {},
    },
  },
  getters: {
    getUser: state => {
      return state.user.data;
    },
    getStatus: state => {
      return state.status;
    },
    getRemember: state => {
      return state.remember;
    },
    getCreditRestantUser: state => {
      return state.user.data.attributs.creditrestant;
    },
    getCurrentTransaction: state => {
      return state.currentTransaction;
    }
  },
  mutations: mutations,
  actions: actions,
  modules: modules,
})
