<template>
  <div id="formContact" class="formContact">
      <div class="logo-section fadeInRight">
        <img src="../assets/icon_popup.png" class="logo"/> 
      </div>
      <div class="contact-form fadeInRight">
            <h2>Formulaire de Contact</h2>
            <form class="form" action="">
                <div class="form-group">
                    <input type="text" class="form-control" id="nom" placeholder="Nom *" v-model="nom">
                </div>
                <div class="form-group">
                    <input type="email" class="form-control" id="email" placeholder="Email *" v-model="email" @blur="validateEmail">
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" id="sujet" placeholder="Sujet *" v-model="sujet">
                </div>              
                 <div class="form-group">
                    <input type="textarea" cols="50" rows="10" class="form-control" id="message" placeholder="Message *" v-model="message">
                </div>
                <div class="form-group-check">
                    <input type="checkbox" class="form-checkbox" id="copie" v-model="copie"><label for="checkbox">Envoyer une copie à votre adresse</label>
                </div>  
            </form>
            <div class="message fadeInUp">
                <p class="error" v-if="errors.length">
                    <ul>
                        <li v-for="error in errors" :key="error">
                            {{ error }}
                        </li>
                    </ul>
                </p>
                <p class="success" v-if="successs.length">
                    <ul>
                        <li v-for="succes in successs" :key="succes">
                            {{ succes }}
                        </li>
                    </ul>
                </p>
            </div>
            <button class="btn-proxi btn-secondary btn-orange end" v-if="boutonactif"  @click="envoyer()">
                    ENVOYER 
            </button>
      </div>
      <div class="cor fadeInRight">
        <h2>Nos coordonnées</h2>
        <ul class="list">
            <li>128 rue BONNAT</li>
            <li>31400 TOULOUSE</li>
            <li>07 86 03 98 42</li>
            <li>http://www.proxiwash.com </li>
        </ul>
      </div>          
  </div>
</template>

<script>

import axios from 'axios';
import {ConfigVars} from "../config-app";

export default {
    name: 'FormContact',
    data() {
        return {
          errors: [],
          successs: [],
          boutonactif:true,
          nom: '',
          email: '',
          sujet: '',
          message: '',
          copie: '',
          erreur: ''
        }
    },
    computed: {
        getStatut () {
            return this.$store.state.status;
        },
    },  
    methods: {
        validateEmail:function() {
            if (/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\\.\\w{2,3})+$/.test(this.email)) {
                this.errors.push('Please enter a valid email address');
            }
        },
        envoyer: function() {
      /*
          console.log("nom : " + this.nom);
          console.log("email : " + this.email);
          console.log("sujet : " + this.sujet);
          console.log("message : " + this.message);
          console.log("copie : " + this.copie);
        */
          this.errors = [];
          this.erreur = "";

          if(this.nom == "") 
          {
        //    console.log("Nom obligatoire");
            this.erreur += 'Champ invalide nom';
            this.errors.push('Nom obligatoire');
          }

          if(this.email == "") 
          {
        //    console.log("Email obligatoire");
            this.erreur += 'Champ invalide Email';
            this.errors.push('Email obligatoire');
          }
          else
          {
            //verification du format
          }

          if(this.sujet == "") 
          {
          //  console.log("Sujet obligatoire");
            this.erreur += 'Champ invalide sujet';
            this.errors.push('Sujet obligatoire');
          }

        if(this.erreur != "")
            console.log("erreur : "+this.erreur);

          /*
          console.log("nom : " + this.nom);
          console.log("email : " + this.email);
          console.log("sujet : " + this.sujet);
          console.log("message : " + this.message);
          console.log("copie : " + this.copie);
          */

          if(this.erreur == "")
          {
            axios({
              method: 'post',
              url: ConfigVars.URL_API + '/utilisateurs/contact',
              data: {
                  nom: this.nom, 
                  sujet: this.sujet,
                  email: this.email,
                  message: this.message,
                  copie: this.copie
              },
              headers: {
                  Authorization: 'Bearer ' + this.$store.state.user.token
              }
              })
              .then((response) => {
                  console.log('success : ', response);
                  console.log('status : ' + response.data.status);

                  if(response.data.status == "success")
                  {
                        //action ok
                        console.log('success');
                        this.boutonactif = false;
                        this.successs.push("Votre message a été envoyé avec succès.");
                  }
                  else
                  {
                        var s_msg_erreur = '';
                        if (response.data.title != "") 
                            s_msg_erreur = 'Erreur : ' + response.data.title;
                        else
                            s_msg_erreur = "Votre message n'a pas pu être envoyé.";
                        this.errors.push(s_msg_erreur);
                  }
              })
              .catch((response) => {
                var s_msg_erreur = '';
                if (response.response) 
                    s_msg_erreur = 'Erreur : ' + response.response.data.title;
                else
                    s_msg_erreur = "Votre message n'a pas pu être envoyé.";
                this.errors.push(s_msg_erreur);
              });
          }
        },
    },
}
</script>

<style lang="scss" scopped>

@import '../sass/button.scss';
@import '../sass/animation.scss';

.logo {
    height: 100px;
}

.end {
    margin-top: 24px;
}

.success {
    padding: 1rem;
    background-color: #FFF;
    border-radius: 10px;
    color: chartreuse;
}

.error {
    padding: 1rem;
    background-color: #FFF;
    border-radius: 10px;
    color: red;
}

.cor {
    background-color: var(--accent);
    border-radius: 24px;
    padding: 2rem;
    text-align: center;
    color: #FFF;
    width: 500px;
    margin-top: 32px;
}

.formContact {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.contact-form {
    margin-top: 38px;
    min-width: 260px;
    color: #FFF;
    width: 100%;
    padding: 2rem;
    text-align: center;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    background-color: var(--accent);
}

@media only screen and (min-width: 425px) {
  .contact-form {
      width: 425px;
  }

  .contact {
      padding-top: 3rem;
  }
}


@media only screen and (min-width: 700px) {
  .contact-form {
      width: 500px;
  }

  .contact {
      padding-top: 3rem;
  }
}

input {
    border: none;
    background: none;
    font-family: 'Nunito';
    font-weight: 400;
    font-size: 1.375rem;
    outline: none;
    width: 100%;
}

.form {
    color: #FFF;

    .form-control {
        margin: 1rem 0;
        color: #FFF;

        border-bottom: 2px solid #FFF;

        &::placeholder {
            color: #FFF;
        }
    }

    .form-group-check {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 38px 0 38px 0;

        font-weight: 600;

        .form-checkbox {
            height: 30px;
            width: 30px;
            
            border-radius: 5px;
        }
    }
}
</style>