<template>
    <div id="paymentMedthod" class="paymentMedthod fadeInRight">

        <!-- BOUTON PAIEMENT PAR COMPTE CLIENT -->
        <div class="paymentMedthod--compte "  v-if="boutonPaiementCompteVisible">
            <button class="btn-proxi margin" v-bind:class="this.typemachineDefaut == '2' ? 'btn-lavelinge' : 'btn-sechelinge'"  v-if="$store.getters.getStatus == 'loggedIn'" @click="setPaymentMethod(MODEPAIEMENT_COMPTE);selected = true">
                Utiliser mon credit
                <p class="creditrestant">Credit restant</p>
                <p>{{ $store.getters.getCreditRestantUser}} &euro;</p>
            </button>
            <button class="btn-proxi margin" @click="$router.push('/login/' + this.id)" v-else v-bind:class="this.typemachineDefaut == '2' ? 'btn-lavelinge' : 'btn-sechelinge'">
                Paiement par compte
                <p>pas de compte ?</p>
                <p>connexion</p>
            </button>
        </div>

        <!-- BOUTON PAIEMENT PAR CB  -->
        <div class="paymentMedthod--carte" v-if="boutonPaiementCBInternetVisible" @click="setPaymentMethod(MODEPAIEMENT_CARTEBANCAIREINTERNET);selected = true"> 
             <button class="btn-proxi" v-bind:class="this.typemachineDefaut == '2' ? 'btn-lavelinge' : 'btn-sechelinge'">CB via Internet</button>
        </div>   

        <!-- BOUTON AUCUN MODE DE PAIEMENT DISPO -->
        <div class="paymentMedthod--carte" v-if="boutonAucunModePaiementVisible" > 
             <button class="btn-proxi" disabled v-bind:class="this.typemachineDefaut == '2' ? 'btn-lavelinge' : 'btn-sechelinge'">AUCUN MODE DE PAIEMENT DISPONIBLE</button>
        </div> 

        <!-- BOUTON PAYER LA MACHINE -->
        <button class="btn-proxi btn-secondary btn-white fadeInUp" @click="payMachine(tarifs.ref,tarifs.prix)" v-if="selected == true" v-bind:class="this.typemachineDefaut == '2' ? 'btn-lavelinge' : 'btn-sechelinge'">
          {{ tarifs.prix }} &euro;<i></i>PAYER LA MACHINE {{ modepaiementCourant == MODEPAIEMENT_CARTEBANCAIREINTERNET ? ( 'EN CB' ) : (modepaiementCourant == MODEPAIEMENT_COMPTE ? ( 'EN COMPTE' ) : '') }}
        </button>

        <div v-if="messageErreur != ''">
            {{ messageErreur }}
        </div>

    </div>
</template>

<script>
import axios from 'axios';
import {ConfigVars} from "../config-app";
import {VarsIHM} from "../config-app";
import {ModesPaiements} from "../config-app";
import {Etats} from "../config-app";

export default {
    name: 'PaymentMethod',
    props: {
        typemachineDefaut : {
            type: String,
            default: '2'
        },
        tarifs: {},
        id: String,
        infoMachine: {},

        // Tableau des modes de paiement de la laverie au format JSON
        //modepaiementsActifsLaverieJSON: String,
        
        // Tableau des modes de paiement de la laverie
        modepaiementsActifsLaverie:[],

    },
    data: function () {
        return {
            confirm: false,
            selected: false,
            modepaiementCourant: 1,

            PAYMENT_AFFICHER_BOUTON_COMPTECLIENT: VarsIHM.PAYMENT_AFFICHER_BOUTON_COMPTECLIENT,
            PAYMENT_AFFICHER_BOUTON_CB: VarsIHM.PAYMENT_AFFICHER_BOUTON_CB,

            MODEPAIEMENT_CARTEBANCAIREINTERNET: ModesPaiements.MODEPAIEMENT_CARTEBANCAIREINTERNET,
            MODEPAIEMENT_COMPTE: ModesPaiements.MODEPAIEMENT_COMPTE,


            modepaiementCompteActifSurLaverie : false,
            modepaiementCBInternetActifSurLaverie : false,

            messageErreur: '',
            paiementEnCours : false,
            interval: null,
        }
    },
    mounted() {
        console.log('type machine pay :',this.typemachineDefaut)

        if ( this.modepaiementsActifsLaverie != null && this.modepaiementsActifsLaverie.length > 0)
        {
            console.log('PARCOURS Modes paiements actifs' , this.modepaiementsActifsLaverie);
            for (var j = 0; j < this.modepaiementsActifsLaverie.length; j++) {
                console.log('Mode : ' , this.modepaiementsActifsLaverie[j] );

                if ( Number(this.modepaiementsActifsLaverie[j].id) == ModesPaiements.MODEPAIEMENT_CARTEBANCAIREINTERNET)
                {
                    console.log('Mode CB Actif' );
                    this.modepaiementCBInternetActifSurLaverie = true;
                }
                else if ( Number(this.modepaiementsActifsLaverie[j].id) == ModesPaiements.MODEPAIEMENT_COMPTE)
                {
                    console.log('Mode COMPTE Actif' );
                    this.modepaiementCompteActifSurLaverie = true;
                }    

            }
        }
        else
            console.log('Aucun modes Data');

    },
    unmounted: function() {
        clearInterval(this.interval);
    },
    computed: {
        boutonPaiementCBInternetVisible: function () {
            console.log('boutonPaiementCBInternetVisible ... ' + VarsIHM.PAYMENT_AFFICHER_BOUTON_CB + ' - ' + this.modepaiementCBInternetActifSurLaverie)
            return VarsIHM.PAYMENT_AFFICHER_BOUTON_CB == true && this.modepaiementCBInternetActifSurLaverie == true;
        },
        boutonPaiementCompteVisible: function () {
            console.log('boutonPaiementCBInternetVisible ... ' + VarsIHM.PAYMENT_AFFICHER_BOUTON_COMPTECLIENT + ' - ' + this.modepaiementCompteActifSurLaverie)
            return VarsIHM.PAYMENT_AFFICHER_BOUTON_COMPTECLIENT == true && this.modepaiementCompteActifSurLaverie == true;
        },
        boutonAucunModePaiementVisible: function () {
            var b_visible = true;
            if ( VarsIHM.PAYMENT_AFFICHER_BOUTON_CB == true  && this.modepaiementCBInternetActifSurLaverie == true)
                b_visible = false;
            if ( VarsIHM.PAYMENT_AFFICHER_BOUTON_COMPTECLIENT == true &&  this.modepaiementCompteActifSurLaverie == true )
                b_visible = false;
            return b_visible;
        },

    },
    methods: {
        setPaymentMethod: function(mode) {
            console.log("setPaymentMethod ... "  + mode)
            //if(this.confirm == true) 
            //{
                //if(mode == 'compte') {
                //    this.$emit('selectedPaymentMethod', 'compte');
                //} else {
                //    this.$emit('selectedPaymentMethod', 'carte');
               // }
            console.log("OK setPaymentMethod " + mode)
            console.log("OK infomachine " , this.infoMachine)
            switch ( mode)
            {
                case ModesPaiements.MODEPAIEMENT_COMPTE:
                    //this.$emit('selectedPaymentMethod', 'compte');
                    this.modepaiementCourant = mode;
                    break;
                case ModesPaiements.MODEPAIEMENT_CARTEBANCAIREINTERNET:
                    //this.$emit('selectedPaymentMethod', 'carte');
                    this.modepaiementCourant = mode;
                    break;

                default:
                    this.modepaiementCourant = 1;
                    break;
            }

            //}
        },
        setPaiementEnCours: function(encours) {
            this.paiementEnCours = encours;
            console.log("====> SET PAIEMENT EN COURS = " + this.paiementEnCours);
        },
        sleep (time) {
            return new Promise((resolve) => setTimeout(resolve, time));
        },

        /**
        * Appel de la fonction checkDemandeodreReservationMachinePourPaiementCB en boucle toutes les 2s
        *
        * @param {Number} _deo_id demandeordre a surveiller
        * @param {Object} _o_html_mercanet objet contenant les params a envoyer a la page de paiement CB Mercanet
        * @param {Number} _ins_idBrut clé site + ins_id
        */
        startLoop_checkDemandeodreReservationMachinePourPaiementCB(deo_id, _o_html_mercanet, _ins_idBrut) {
            this.interval = setInterval(() => {
                this.checkDemandeodreReservationMachinePourPaiementCB(deo_id, _o_html_mercanet,_ins_idBrut)
            }, 2000);
        },

        /**
        * Arret de la boucle d'appel de la fonction checkDemandeodreReservationMachinePourPaiementCB
        */
        stopLoop_checkDemandeodreReservationMachinePourPaiementCB() {
            clearInterval(this.interval);
        },

        /**
        * Fonction qui surveille l'ordre de reservation machine pour paiement CB
        * Si la reservation est OK : appel de la page de paiement CB Mercanet
        *
        * @param {Number} _deo_id demandeordre a surveiller
        * @param {Object} _o_html_mercanet objet contenant les params a envoyer a la page de paiement CB Mercanet ( provenant de l'API )
        * @param {Number} _ins_idBrut clé site + ins_id
        */
        checkDemandeodreReservationMachinePourPaiementCB: function(_deo_id, _o_html_mercanet,_ins_idBrut) 
        {
            console.log('Attente reponse reserver - ordre ' + _deo_id);

            // Récupération via l'API de la demandeordre de reservation machine
            axios.get( ConfigVars.URL_API  + 'machines/' + _ins_idBrut + '/ordre', { params: {deo_id: _deo_id} } )
            
            // Reception de la reponse de l'API
            .then((responseOrdre) => {
                console.log('success ordre', responseOrdre)
                
                switch ( Number(responseOrdre.data.data.ordre.deo_etat) )
                {       
                    // ETAT VALIDE
                    case Etats.ETAT_VALIDE: 
                    case Etats.ETAT_TERMINE: 
                        this.stopLoop_checkDemandeodreReservationMachinePourPaiementCB();
                        console.log('######################################')
                        console.log('ORDRE DE RESERVATION OK : APPEL DE MERCANET ...')
                        console.log('######################################')
                        this.messageErreur = 'Redirection vers la page de paiement CB ...';                                         
                        
                        this.$router.push({ name: 'FormMercanet', params: { 
                            autoredirection: 'true',
                            action: _o_html_mercanet.action, 
                            Seal: _o_html_mercanet.Seal, 
                            InterfaceVersion: _o_html_mercanet.InterfaceVersion,
                            Data: _o_html_mercanet.Data,
                        }})
                        break;

                    case Etats.ETAT_ERREUR: 
                        this.stopLoop_checkDemandeodreReservationMachinePourPaiementCB();
                        console.log('###############################')
                        console.log('ERREUR A LA RESERVATION : ' + responseOrdre.data.data.ordre.message)
                        console.log('###############################')
                        this.messageErreur =  responseOrdre.data.data.ordre.message;
                        break;

                    case Etats.ETAT_ANNULATION:
                        this.stopLoop_checkDemandeodreReservationMachinePourPaiementCB();
                        console.log('###############################')
                        console.log('ORDRE DE RESERVATION ANNULE')
                        console.log('###############################')
                        this.messageErreur = 'PAIEMENT ANNULE';
                        break;

                    default: 
                        console.log('retour pas recu ' + responseOrdre.data.data.ordre.deo_etat)
                        break;
                }

            })
            .catch((responseOrdre) => {
                console.log('error get ordre',responseOrdre);
                this.setPaiementEnCours(false);
            });
        },

        //
        // CLIC SUR LE BOUTON 'PAYER' ( EN COMPTE OU EN CB )
        //
        payMachine: function(ref,prx) {

            if ( this.paiementEnCours == false)
            {
                this.setPaiementEnCours(true);

                console.log("###################################################")
                console.log("EXECUTION DE PAYER LA MACHINE ...")
                console.log("###################################################")
                console.log(ref,prx)
                console.log(this.$store.state.user.token)
                console.log("OK infomachine " , this.infoMachine)

                this.messageErreur = '';

                // APPEL DE PING LAVERIE VIA API
                axios({
                    method: 'get',
                    url: ConfigVars.URL_API + 'laveries/' + this.infoMachine.sitcle + '/ping',
                    data: {}
                })
                .then((response) => {
                    console.log('success Ping : ', response)  ;

                    if ( response.data.data.ping.pingok == true )
                    {
                        // CAS 1 : PING LAVERIE OK
                        console.log('Ping ok ... ')  ;
                        switch ( this.modepaiementCourant)
                        {
                            // MODE DE PAIEMENT CHOISI : COMPTE
                            case ModesPaiements.MODEPAIEMENT_COMPTE:
                                this.payMachine_compteClient(ref,prx);
                                break;

                            // MODE DE PAIEMENT CHOISI : CB INTERNET
                            case ModesPaiements.MODEPAIEMENT_CARTEBANCAIREINTERNET:
                                this.payMachine_carteBancaireEnLigne(ref,prx);
                                break;
                        }
                    }
                    else
                    {
                        // CAS 2 : PING LAVERIE NOK
                        console.log('Ping NOK !! ')  ;
                        this.messageErreur = 'LAVERIE PAS CONNECTEE !! MERCI DE REESSAYER PLUS TARD';
                        this.setPaiementEnCours(false);
                    }

                })
                .catch((response) => {
                    console.log('error',response);
                    this.messageErreur = 'ERREUR : IMPOSSIBLE DE RECUPERER L\'ETAT DE LA LAVERIE !! MERCI DE REESSAYER PLUS TARD';
                    this.setPaiementEnCours(false);
               });

            }
            else
            {
                console.log("###################################################")
                console.log("ERREUR : UN PAIEMENT EST DEJA EN COURS ...")
                console.log("###################################################")
            }

        },

        payMachine_compteClient: function(ref,prx) {
            // Envoie a la centrale la demande d'ordre de reglement en compte
            // Retour = demandeordre pour surveiller l'evolution de l'ordre
            axios({
                method: 'post',
                url: ConfigVars.URL_API + 'machines/' + this.id + '/regler_en_compte',
                data: {
                    reference: ref, 
                    prix: prx
                },
                headers: {
                    Authorization: 'Bearer ' + this.$store.state.user.token
                }
            })
            .then((response) => {
                console.log('success', response)  

                this.$emit('setOrder',response.data.data.ordre);

                this.setPaiementEnCours(false);
            })
            .catch((response) => {
                console.log('error',response);
                this.setPaiementEnCours(false);
            });
        },

        payMachine_carteBancaireEnLigne: function(ref,prx) {
            console.log('ref : ' +this.tarifs.ref_nom);
            //console.log('id2 : ' + this.id.substr(6, this.id.length - 5));

            var ins_idLocal = '';
            var ins_idbrutLocal = '';
            if ( this.id.length > 20)
            {
                console.log('id contient la cle : on fait substring');
                ins_idbrutLocal = this.id;
                ins_idLocal = this.id.substr(6, this.id.length - 5);
            }
            else
            {
                console.log('id ne contient pas la cle');
                ins_idbrutLocal = this.infoMachine.sitcle + this.id;
                ins_idLocal = this.id;
            }

            console.log('Res : ins_idbrutLocal = ' + ins_idbrutLocal + ' - ins_idLocal = ' + ins_idLocal);

            // 1) APPEL DE L'API pour generer les params a envoyer a Mercanet
            axios({
                method: 'post',
                url: ConfigVars.URL_API + 'utilisateurs/cb_internet',
                data: {
                    ref_id: ref, 
                    montant: prx ,
                    sit_id: this.infoMachine.sitid, 
                    // ins_id 
                    ins_id: ins_idLocal, 
                    // ins_idbrut = clé du site + clé instance sauf anciens qr code qui ont que ins_id
                    ins_idbrut: ins_idbrutLocal, 
                    ref_nom : this.tarifs.ref_nom,
                    returnurl: ConfigVars.URL_RETOURMERCANET_PAIEMENTCB
                },
                headers: {
                    Authorization: 'Bearer ' + this.$store.state.user.token
                }
            })
            .then((response_cb_internet) => {
                console.log('success cb_internet', response_cb_internet)  

                // SI LA MACHINE EST RESERVEE POUR SOI MEME : PAS LA PEINE DE RESERVER

                /*var b_pasdereservation = false;
                // S'il n'y a pas de reservation ou il y a une reservation pour soi-meme
                if ( b_pasdereservation )
                {


                    this.messageErreur = 'Redirection vers la page de paiement CB ...';                                         
                            
                    this.$router.push({ name: 'FormMercanet', params: { 
                        autoredirection: 'true',
                        action: response_cb_internet.data.data.mercanet.html.action, 
                        Seal: response_cb_internet.data.data.mercanet.html.Seal, 
                        InterfaceVersion: response_cb_internet.data.data.mercanet.html.InterfaceVersion,
                        Data: response_cb_internet.data.data.mercanet.html.Data,
                    }})

                }
                else
                {*/
                    // SINON
                    // 2) APPEL API POUR RESERVER LA MACHINE POUR PAIEMENT CB
                    // En passant transactionreference
                    // NOTE : Au retour de la transaction Mercanet :
                    // - Si paiement échoué : avec le transaction reference, on envoie un ordre a la CPI pour cloturer la reservation
                    // - Si paiement ok, la CPI lance la machine et cloture la reservation 

                    // Envoie a la centrale la demande d'ordre de reservation pour paiement cb
                    // Retour = demandeordre pour surveiller l'evolution de l'ordre
                    axios({
                        method: 'post',
                        url: ConfigVars.URL_API + 'machines/' + ins_idbrutLocal + '/reserver',
                        data: {
                            transactionreference: response_cb_internet.data.data.mercanet.html.transactionreference                                         
                        },
                        headers: {
                            Authorization: 'Bearer ' + this.$store.state.user.token
                        }
                    })
                    .then((response_reserver) => {
                        console.log('success reserver', response_reserver)  

                        // Appel de la Fonction qui surveille en boucle l'ordre de reservation machine pour paiement CB
                        this.startLoop_checkDemandeodreReservationMachinePourPaiementCB(
                            response_reserver.data.data.ordre,
                            response_cb_internet.data.data.mercanet.html,
                            ins_idbrutLocal
                        );

                        this.setPaiementEnCours(false);

                    })
                    .catch((response) => {
                        console.log('error reserver',response);
                        this.setPaiementEnCours(false);
                    });
                //}

            })
            .catch((response_cb_internet) => {
                console.log('error',response_cb_internet);
                this.setPaiementEnCours(false);
            });
        }


    }
}   
</script>

<style scoped lang="scss">

@import '../sass/button.scss';
@import '../sass/animation.scss';

.paymentMedthod {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.margin {
    margin-bottom: 32px;
}



</style>