<template>
    <div id="conseils" class="conseils">
        <ul class="menu-profils">
            <li @click="selected = 1" v-bind:class="{ active: selected === 1 }">
                <h3>Règles de sécurité</h3>
            </li>
            <li @click="selected = 2" v-bind:class="{ active: selected === 2 }">
                <h3>Utilisation des lave-linge</h3>
            </li>
            <li @click="selected = 3;" v-bind:class="{ active: selected === 3 }">
                <h3>Utilisation des sèche-linge</h3>
            </li>
        </ul>
        <div class="ct">
            <div class="regle" v-if="selected == 1">
            <h4>Règles de sécurité</h4>
            <p>Avant toute utilisation, pour votre sécurité et celle des autres, respecter les règles suivantes :</p>
            <ul class="list">
                <li>ne jamais tenter d’ouvrir une machine avant son arrêt complet</li>
                <li>ne jamais essayer de retirer du linge d’une machine dont le tambour est encore en rotation. Risque de blessure très grave</li>
                <li>ne pas toucher le hublot d’une machine en cours de fonctionnement. Risque de brûlure.</li>
                <li>ne pas laisser les enfants manipuler les machines</li>
                <li>ne jamais tenter de dépanner soi-même, risque de détérioration du matériel ou pire de blessure.</li>
            </ul>
            <p>Pour toute défaillance ou anomalie, appelez Cogédia, le service d’assistance et dépannage de PROXIWASH sont gratuits.
            Il ne doit jamais vous être réclamé quelques indemnités que ce soit : frais de déplacement, pièce remplacée, temps passé, etc..</p>
            </div>
            <div class="util-lav" v-if="selected == 2">

            </div>
            <div class="utils-sech" v-if="selected == 3">

            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    name: 'Conseils',
    data() {
        return {
            selected: 1,
        }
    }
}
</script>

<style lang="scss" scopped>

.conseils {

    & .ct {
        padding: 1rem;
    }
    padding: 1rem;
    & .menu-profils {
        background-color: var(--primary);
        padding: 1rem;
        border-radius: 10px;
        color: #FFF;
        

        & li {
            cursor: pointer;
            transition: 0.1s;

            &:hover {
                background-color: rgba(255, 255, 255, 0.548);
            }
        }
    }   

    & .regle {
        & .list {
            list-style-type: disc;
        }
    }

    
    
}


</style>