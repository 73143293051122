<template>
    <div id="machine--status" class="machine--status dispo">
        <h3>Lancement Réussi !</h3>

        <div class="machine__card--status">
            <div style="height:140px;width: 140px;display: flex;flex-direction:column;">
                    <vue3-chart-js
                        :id="doughnutChart.id"
                        ref="chartRef"
                        :type="doughnutChart.type"
                        :data="doughnutChart.data"
                        :options="doughnutChart.options"
                    ></vue3-chart-js>
                </div>
        </div>
        <p>Votre machine ne démarre pas ? Assistance: 07 86 03 98 42</p>
        <p>&nbsp;</p>
        <div v-if="b_lienListeMachine">
            <router-link v-bind:to="routerLinkRetour">
                <img src="@/assets/icon_back.svg" alt="Retour"> Retour aux machines
            </router-link>
        </div>

    </div>
</template>

<script>
import { ref } from 'vue'
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'

export default {
    name: 'MachineStatus',
    components: {
        Vue3ChartJs,
    },
    props: {
        etanom: String,
        dureeerestante: String,
        program: String,
        infoMachine: {},
        id: String
    },
    data: function() {
        return {
            interval: null,
            time: 0,
            datacollection: null,
        }
    },
    mounted: function()  {
        console.log('MachineStatus ! ' + this.id + ' - ' , this.infoMachine);
    },
     computed: {
        b_lienListeMachine: function () {
            console.log('b_lienListeMachine ... ' )
            return this.infoMachine != null && this.infoMachine.sitcle != '';
        },
        routerLinkRetour: function () {
            console.log('routerLinkRetour' ,this.infoMachine);
            if ( this.infoMachine != null && this.infoMachine.sitcle != '' )
                //return '{ name:\'Laverie\', { code: '+this.infoMachine.sitcle+', listMachineSelectedDefaut: true, listMachineAfficherLaveLingeDefaut: true }}';
                return '/laverie/' + this.infoMachine.sitcle + '/true/' + ( this.infoMachine.type == "2" ? 'true' : 'false' );
            else
                return '';
        },
    },
    setup () {
        const chartRef = ref(null)
        /*
        var grey = 1200;
        var red = 300;
        */

       var color = '#FF8C7D'

      
        var green = 1200

        
        

        const doughnutChart = {
        id: 'doughnut',
        type: 'doughnut',
        data: {
            datasets: [
            {
                borderWidth: 0,
                backgroundColor: [
                color,
                ],
                data: [green]
            }
            ]
        },
        options: {
            responsive: true,
            cutout: 58,
            plugins: {
                
            }
        }
        }

        /*

        const updateChartTermine = () => {
            doughnutChart.options.plugins.title = {
                text: 'Updated Chart',
                display: true
            }
            doughnutChart.data.datasets = [
                {
                data: [red, grey]
                }
            ]
        chartRef.value.update(250)
        }

        const updateChartDispo = () => {
            doughnutChart.options.plugins.title = {
                text: 'Updated Chart',
                display: true
            }
            doughnutChart.data.datasets = [
                {
                data: [grey],
                backgroundColor: [
                    color,
                    ],
                }
            ]
            chartRef.value.update(250);
        }

        */

        return {
        doughnutChart,
        //updateChartTermine,
        //updateChartDispo,
        chartRef
        }
    },
}   
</script>

<style scoped lang="scss">

.machine--status {
    background-color: #FFF;
    color: var(--primary);
    padding: 2rem;
    border-radius: 10px;
    width: 300px;
    text-align: center;
}

.machine__card--status {
    display: flex;
    justify-content: center;
}


.etattime{
    position: absolute;

}


</style>