<template>
    <div id="scanner">
        <h1>Scanner</h1>
    </div>
</template>

<script>
export default {
    name: 'Scanner',
}
</script>

<style>

</style>