<template>
  <div id="creerCompte" class="creerCompte">
      <div class="logo-section fadeInRight">
            <img src="../assets/icon_popup.png" class="logo"/>
        </div>
        <div class="login-form fadeInRight">
            <form class="form" action="">
                <div class="form-group">
                    <input type="text" class="form-control" id="nom" placeholder="Nom *" v-model="nom">
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" id="prenom" placeholder="Prenom *" v-model="prenom">
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" id="identifiant" placeholder="Identifiant *" v-model="identifiant">
                </div>
                <div class="form-group">
                    <input type="email" class="form-control" id="email" placeholder="Email *" v-model="email">
                </div>
                <div class="form-group">
                    <input type="email" class="form-control" id="emailConfirm" placeholder="Email (Confirmation)" v-model="emailConfirm">
                </div>
                <div class="form-group">
                    <input type="password" class="form-control" id="password" placeholder="Mot de passe *" v-model="password">
                </div>
                <div class="form-group">
                    <input type="password" class="form-control" id="passwordConfirm" placeholder="Mot de passe (Confirmation)" v-model="passwordConfirm">
                </div>
                <div class="form-group">
                    <input type="tel" class="form-control" id="tel" placeholder="Téléphone Mobile (optionnel)" v-model="tel">
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" id="badge" placeholder="Numéro de badge (optionnel)">
                </div>
                <div class="form-group">
                    <label for="ce" class="ce">Carte etudiante</label>
                    <input type="file" class="form-control" id="ce" placeholder="Carte etudiante" v-bind="ce" accept=".jpg, .jpeg, .png" v-on:change="handleImage">
                    <img :src="ce">
                </div>
            </form>
            <button class="btn-proxi btn-secondary btn-orange end" @click="register()">
                    ENREGISTRER 
            </button>

            <p class="messagelav" v-if="t_messagesValidationForm.length">
                <ul>
                    <li v-bind:class="o_message.classe" v-for="o_message in t_messagesValidationForm" :key="o_message">
                        {{ o_message.message }}
                    </li>
                </ul>
            </p>

        </div>
  </div>
</template>

<script>

import axios from 'axios';
import {ConfigVars} from "../config-app";

export default {
    name: 'CreerCompte',
    data() {
        return {
            errors: [],
            identifiant: '',
            prenom: '',
            password: '',
            passwordConfirm: '',
            emailConfirm: '',
            nom: '',
            email: '',
            tel: '',
            nBadge: '',
            ce: null,
            rememberMe: false,
            message: '',
            // Messages affichés a la validation du form
            t_messagesValidationForm: []
        }
    },
    computed: {
        getStatut () {
            return this.$store.state.status;
        },
    },  
    methods: {
        handleImage(e) {
            const selectedImage = e.target.files[0];
            this.ce = selectedImage;
        },
        createBase64Image(fileObject) {
            //const reader = new FileReader();

            //reader.readAsBinaryString(fileObject);

            console.log(fileObject)
        },
        register: function() {
            this.errors = [];
            this.t_messagesValidationForm = [];

            if(this.email != this.emailConfirm) 
            {
                this.message = 'Email pas identique'
                var objMessage2 = {
                    message: 'Email pas identique !',
                    classe:"subtitle"
                }
                this.t_messagesValidationForm.push(objMessage2);
            }

            if(this.password != this.passwordConfirm) 
            {
                this.message = 'Mot de passe pas identique'
                var objMessage = {
                    message: 'Mot de passe pas identique !',
                    classe:"subtitle"
                }
                this.t_messagesValidationForm.push(objMessage);
            }

            if ( this.t_messagesValidationForm.length == 0)
            {
                console.log('Tentative de creation')
                console.log(this.nom);
                console.log(this.prenom);
                console.log(this.identifiant);
                console.log(this.password);
                console.log(this.tel);
                console.log(this.nBadge);
                console.log(this.ce);
                
                axios({
                    method: 'post',
                    url: ConfigVars.URL_API + 'utilisateurs/create',
                    data: {
                        nom: this.nom, 
                        prenom: this.prenom,
                        login: this.identifiant,
                        password: this.password,
                        badge: this.nBadge,
                        mobile: this.tel,
                        mail: this.email,
                    },
                    headers: {
                        Authorization: 'Bearer ' + this.$store.state.user.token
                    }
                    })
                    .then((response) => {
                        console.log('success', response)

                        var objMessage = {
                            message: 'Compte créé avec succès',
                            classe:"subtitle"
                        }
                        this.t_messagesValidationForm.push(objMessage);

                        //this.$router.push('/login'); 
                    })
                    .catch((response) =>{
                        
                        var s_msg_erreur = '';
                        if (response.response) {
                            // Request made and server responded
                            s_msg_erreur = 'Erreur : ' + response.response.data.title;
                        }
                        else {
                            // Something happened in setting up the request that triggered an Error
                            //console.log('Error', response.message);
                            s_msg_erreur = 'Erreur a la création du compte !' ;
                        }

                        var objMessage = {
                            message: s_msg_erreur,
                            classe:"subtitle"
                        }
                        this.t_messagesValidationForm.push(objMessage);

                        this.errors.push(s_msg_erreur);
                    });
            }     
            
        },
    },
}
</script>

<style lang="scss" scopped>

@import '../sass/button.scss';
@import '../sass/animation.scss';

.logo {
    height: 100px;
}

.end {
    margin-top: 24px;
}

.creerCompte {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.ce {
    font-weight: 400;
    font-size: 1.375rem;
}

.login-form {
    margin-top: 38px;
    min-width: 260px;
    width: 100%;
    padding: 2rem;

    text-align: center;

    border-top-left-radius: 25px;
    border-top-right-radius: 25px;

    background-color: var(--accent);

    & .messagelav {
            background-color: #FFF;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 4rem;
            margin-top: 24px;
            border-radius: 10px;
        }
}

@media only screen and (min-width: 425px) {
  .login-form {
      width: 425px;
  }

  .login {
      padding-top: 3rem;
  }
}


@media only screen and (min-width: 700px) {
  .login-form {
      width: 500px;
  }

  .login {
      padding-top: 3rem;
  }
}

input {
    border: none;
    background: none;
    font-family: 'Nunito';
    font-weight: 400;
    font-size: 1.375rem;
    outline: none;
    width: 100%;
}

.form {
    color: #FFF;

    .form-control {
        margin: 1rem 0;
        color: #FFF;

        border-bottom: 2px solid #FFF;

        &::placeholder {
            color: #FFF;
        }
    }

    .form-group-check {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 38px 0 38px 0;

        font-weight: 600;

        .form-checkbox {
            height: 30px;
            width: 30px;
            
            border-radius: 5px;
        }
    }

    .reset-pass {
        margin-bottom: 38px;
        & span {
            color: #FFF;
            border-bottom: solid 1px #FFF;
        }
    }
}
</style>