<template>
  <div id="credit" class="credit-s">
      <div class="logo-section">
            <img src="@/assets/icon_popup.png" class="logo"/>
        </div>
      <div class="container-credit">
          <h2 class="title">Recharge compte</h2>
          <select class="select" name="Select" id="cr" v-model="montantSelected">
            <option value="">Sélectionner un montant</option>
            <option value="20">20 &euro;</option>
            <option value="30">30 &euro;</option>
            <option value="40">40 &euro;</option>
            <option value="50">50 &euro;</option>
        </select>
        <button class="btn-proxi btn-secondary btn-orange fadeInUp" v-if="montantSelected != ''" @click="crediter()">
           RECHARGER {{ montantSelected }} &euro;<i></i> 
        </button>
      </div>  
  </div>
</template>

<script>
import axios from 'axios';
import {ConfigVars} from "../config-app";

export default {
    name: 'Credit',
    data() {
        return {
            montantSelected: '',
            transaction: {},
            status: '',
        }
    },
    methods: {
        crediter() {
            axios({
                method: 'post',
                url: ConfigVars.URL_API  + 'utilisateurs/' + this.$store.state.user.data.attributs.login + '/recharger',
                data: {
                    montant: this.montantSelected, 
                    returnurl: ConfigVars.URL_RETOURMERCANET_RECHARGE,
                },
                headers: {
                    Authorization: 'Bearer ' + this.$store.state.user.token
                }
            })
            .then((response) => {
                console.log('success', response) 
                this.transaction = response.data.data;
                this.$store.dispatch('setCurrentTransaction',response.data.data);
                this.$router.push({ name: 'FormMercanet', params: { 
                        action: this.transaction.mercanet.html.action, 
                        Seal: this.transaction.mercanet.html.Seal, 
                        InterfaceVersion: this.transaction.mercanet.html.InterfaceVersion,
                        Data: this.transaction.mercanet.html.Data,
                    }
                })
            })
            .catch((response) => {
                console.log('error',response);
            });
        },
    }
}
</script>

<style lang="scss" scopped>
 @import '../sass/button';
  @import '../sass/animation';

 .credit-s {
     display: flex;
     flex-direction: column;
     align-items: center;
     padding: 1rem;

     & .container-credit {
         transition: 0.3s;

         & .title {
             color: #FFF;
             margin-bottom: 20px;
         }
         margin-top: 30px;
        display: flex;
        flex-direction: column;
        border-radius: 25px;
        padding: 3rem;
        background-color: var(--accent);

        & .select {
            margin-bottom: 40px;
        }
    }
 }

 
</style>