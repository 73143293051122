<template>
    <nav id="nav" class="nav">
      <div class="nav__brand">
        <router-link class="logo-content" to="/" @click="switchFalse()">
          <img class="logo-mob" src="../assets/logo-mob.svg" alt="Logo Proxiwash">
          <img class="logo" src="../assets/logo.png" alt="Logo Proxiwash">
        </router-link>
      </div>
      <div class="nav__link">
        <div v-if="$store.state.status == 'loggedIn'" class="deskcontainer">
          <div class="credit-desktop" @click="switchIsActive()">
            <div class="credit__restant">{{ $store.state.user.data.attributs.creditrestant }} &euro;</div>
            <router-link to="/credit">
              <div class="credit__btn">
                <div></div>
              </div>
            </router-link>
          </div>
          <div class="nav__item fav__lav_desktop">
            <router-link class="favlav__btn" :to="{ name: 'Laverie', params: { code: $store.state.user.data.attributs.sitefavoricle, nomLav: $store.state.user.data.attributs.sitefavorinom }}" @click="switchFalse()" v-if="$store.state.user.data.attributs.sitefavoricle != '1'">
              <img class="img--fav" src="../assets/icon_laverie_favorite.svg" alt="Laverie Favorite">
            </router-link>
          </div>
        </div>
        <div class="menu nav__item" v-bind:class="{ isActive: isActive }">
          
          <div v-if="isDev" class="menu__item" style="color: Red; background-color: Red">
            <router-link @click="switchFalse()" class="lien-menu" to="/">VERSION DEV</router-link>
          </div>
          <div class="menu__item">
            <router-link @click="switchFalse()" class="lien-menu" to="/">accueil</router-link>
          </div>
          <div class="menu__item">
            <router-link @click="switchFalse()" class="lien-menu" to="/weblaverie">les laveries</router-link>
          </div>
          <div class="menu__item">
            <router-link @click="switchFalse()" class="lien-menu" to="/creeuncompte">créer un compte</router-link>
          </div>
          <div class="menu__item">
            <router-link @click="switchFalse()" class="lien-menu" to="/contact">contact</router-link>
          </div>
          <div class="menu__item account">
            <router-link class="compte" to="/profile" v-if="$store.state.status == 'loggedIn'" @click="switchFalse()">
              <div>
                <img src="../assets/person_outline_white_24dp.svg" alt="Compte">
              </div>
              <div class="title">mon compte</div>
              <div class="subtitle">{{ $store.state.user.data.attributs.nom }}</div>
            </router-link>
            <router-link class="compte" to="/login" v-else @click="switchIsActive()">
              <div>
                <img src="../assets/person_outline_white_24dp.svg" alt="Compte">
              </div>
              <div class="title">mon compte</div>
              <div class="subtitle">connexion</div>
            </router-link>
          </div>
        </div>
        <div class="nav__item">
          <div class="credit" v-if="$store.state.status == 'loggedIn'" @click="switchFalse()">
            <div class="credit__restant">{{ $store.state.user.data.attributs.creditrestant }} &euro;</div>
            <router-link to="/credit">
              <div class="credit__btn">
                <div></div>
              </div>
            </router-link>
          </div>
        </div>
        <div class="nav__item fav__lav">
          <router-link class="favlav__btn" :to="{ name: 'Laverie', params: { code: $store.state.user.data.attributs.sitefavoricle, nomLav: $store.state.user.data.attributs.sitefavorinom }}" @click="switchFalse()" v-if="$store.state.status == 'loggedIn'">
            <img class="img--fav" src="../assets/icon_laverie_favorite.svg" alt="Laverie Favorite">
          </router-link>
        </div>
        <div class="nav__item stu__account">
          <router-link class="stuaccount__btn" to="/profile" @click="switchFalse()" v-if="$store.state.status == 'loggedIn'">
            <img src="../assets/Icon_account_student.svg" alt="Compte étudiant">
          </router-link>
          <router-link class="stuaccount__btn" to="/login" @click="switchFalse()" v-else>
            <img src="../assets/Icon_account_student.svg" alt="Compte étudiant">
          </router-link>
        </div>
        <div class="nav__item">
          <div class="menu__icon" v-bind:class="{ isActive: isActive }" @click="switchIsActive()">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </nav>
</template>

<script>

import {ConfigVars} from "../config-app";

export default {
  name: 'nav-menu',
  data() {
    return {
      isActive: false,
      isDev:false
    }
  },
  mounted: function() {
    if ( ConfigVars.URL_API == 'https://dev.proxiwash.com/api/')
      this.isDev = true;
  },
  methods: {
    switchIsActive: function() {
      if (window.matchMedia("(min-width: 1200px)").matches) {
        console.log("Version desktop");
      } else {
        this.isActive = !this.isActive;
      }
      
    },
    switchFalse: function() {
        this.isActive = false;
      }
      
    }
  }

</script>

<style scoped lang="scss">
.fav__lav_desktop {
  display: none;
}

.nav {
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: #FFF;

  z-index: 1000;
}

.credit-desktop {
  display: none;
  margin-right: 10px;
  align-items: center;
  height: 32px;
  padding-left: 0.8rem;
  line-height: 1rem;

  color: #FFF;
  background-color: var(--primary);

  border-radius: 25px;
}

.nav__brand {
  height: 100%;
  padding-top: 4px;
}

.deskcontainer {
  display: flex;
}

.deskcontainer img{
  vertical-align: center;
}

.logo {
  display: none;
}

.logo-mob {
  height: 80%;
}

.nav__link {
  height: 100%;
  display: flex;
  align-items: center;
}

.fav__lav {
  margin-right: 10px;
}

.stu__account {
  margin-right: 10px;
}

.credit {
  display: flex;
  margin-right: 10px;
  align-items: center;
  height: 32px;
  padding-left: 0.8rem;
  line-height: 1rem;

  color: #FFF;
  background-color: var(--primary);

  border-radius: 25px;
}

.credit__restant {
  font-weight: bold;
}

.credit__btn {
  margin-left: 11px;
  height: 38px;
  width: 38px;
  display: grid;
  place-items: center;

  border-radius: 50%;
  border: #FFF solid 4px;
  
  background-color: var(--accent);
}

.credit__btn div {
  height: 4px;
  width: 14px;

  border-radius: 2px;

  background-color: #FFF;
}

.credit__btn div::after {
  content: '';
  display: block;
  height: 4px;
  width: 14px;

  border-radius: 2px;

  background-color: #FFF;

  transform: rotate(90deg);
}

.favlav__btn img {
  vertical-align: middle;
}

.stuaccount__btn img {
  vertical-align: middle;
}

.menu__icon {
  width: 20px;

  cursor: pointer;

  z-index: 3000;
}

.menu__icon.isActive div:first-child {
  transform: translateY(10px) rotate(45deg);
} 

.menu__icon.isActive div:nth-child(2) {
  opacity: 0;
} 

.menu__icon.isActive div:last-child {
  transform: translateY(-10px) rotate(-45deg);
} 

.menu__icon div {
  height: 4px;

  border-radius: 2px;

  background-color: var(--primary);
  transition-duration: 0.3s;
}

.menu__icon div:nth-child(2) {
  margin-top: 6px;
  margin-bottom: 6px;
}

.menu {
  position: absolute;
  top: 60px;
  left: 0;
  bottom: 0;
  right: 0;
  height: 0;
  overflow: hidden;

  background-color: #FFF;

  z-index: 5000;
  transition-duration: 0.2s;
}

.menu__item {
  margin: 0 8px 0 8px;
  padding: 1rem;
  text-align: center;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(7, 124, 154, 0.3);

  a {
    text-decoration: none;
    color: var(--primary);
    font-weight: 800;
  }
}

.menu.isActive {
  height: 100vh;
}

.account {
  background-color: var(--accent);

  .title {
    color: #FFF;
    letter-spacing: 0.4px;
  }

  .subtitle {
    color: #FFF;
    font-size: 0.6875rem;
    letter-spacing: 2.2px;
  }
  
}

@media only screen and (min-width: 320px) {
  .nav {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .fav__lav {
    margin-right: 14px;
  }

  .stu__account {
    margin-right: 14px;
  }

  .credit {
    margin-right: 14px;
  } 
}

@media only screen and (min-width: 375px) {
  .nav {
    padding-left: 1.3rem;
    padding-right: 1.3rem;
  }

  .fav__lav {
    margin-right: 20px;
  }

  .stu__account {
    margin-right: 20px;
  }

  .credit {
    margin-right: 20px;
  } 
}

@media only screen and (min-width: 1200px) {

  .nav__item .router-link-exact-active::after {
      content: '';
      background-color: var(--accent);
      display: block;
      height: 3px;
  }

  .fav__lav_desktop {
    display: inherit;
  }

  

  .fav__lav_desktop .router-link-exact-active::after {
      content: '';
      background-color: var(--accent);
      display: none;
      height: 3px;
  }

.credit-desktop {
  display: flex;
  margin-right: 10px;
  align-items: center;
  height: 32px;
  padding-left: 0.8rem;
  line-height: 1rem;

  color: #FFF;
  background-color: var(--primary);

  border-radius: 25px;
}


.credit-desktop .router-link-exact-active::after {
  display: none;
}
  .nav {
    height: 110px;
    padding-left: 0;
    padding-right: 0;
    box-shadow: 0 0 5px #42527321;
  }

  .nav__brand {
    height: 100%;
    padding-left: 8px;
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  .logo {
    display: inline;
    height: 2.5rem;
  }

  .logo-mob {
    display: none;
  }

  .fav__lav {
    display: none;
  }

  .stu__account {
    display: none;
  }

  .credit {
    display: none;
  }

  .menu__icon {
    display: none;
  }

  .menu {
    height: 100%;
    position: static;
    display: flex;
    align-items: center;
  }

  .menu__item {
    margin: 0;
    padding-right: 1rem;
    border: none;
    font-size: 1rem;

    a {
      font-weight: 800;
    }
    
  }

  .account {
    padding: 1rem;
    height: 100%;
    background-image: url(../assets/icon_account.png);
    background-position: 70px 8px;
    background-size: 32px;
    background-repeat: no-repeat;

    img {
      height: 32px;
    }

    .title {
      font-weight: bold;
      font-size: 0.8rem;
    }

    .subtitle {
      font-weight: 400;
    }
  }
}

@media only screen and (min-width: 1350px) {
  .logo {
    display: inline;
    height: 3.5rem;
  }
}
</style>
