<template>
  <div class="home" id="home">
    <section class="intro">
      <div class="label fadeInRight">
        <h1>PROXWASH, LA LAVERIE <span>CONNECTÉE</span> PRÈS DE CHEZ VOUS !</h1>
        <p>Contrôlez l’avancée du nettoyage de votre linge depuis votre mobile !</p>
        <router-link to="/weblaverie">
          <button class="btn-proxi btn-primary btn-orange">
            Choisir la laverie
          </button>
        </router-link>
      </div>
    </section>
    <!--
    <section class="benefits">
      <div class="label">
        <h1>BÉNÉFICIEZ D’AVANTAGES EN CRÉANT VOTRE COMPTE !</h1>
        <ul>
          <li>Visualiser en temps réel l’activité des machines de votre laverie</li>
          <li>Etre alerter par mail de la disponibilité des machines</li>
          <li>10% de remise pour tous et 40% pour les étudiants !</li>
        </ul>
        <router-link to="/weblaverie">
            <button class="btn-proxi btn-primary btn-bleu">
              Créer un compte
            </button>
          </router-link>
      </div>
      
      </section>
    <HelloWorld msg="Welcome to Your Vue.js App"/>
    <HelloWorld msg="Welcome to Your Vue.js App"/>
    <HelloWorld msg="Welcome to Your Vue.js App"/>
    <HelloWorld msg="Welcome to Your Vue.js App"/>
    <HelloWorld msg="Welcome to Your Vue.js App"/>
    -->
  </div>
</template>

<script>

export default {
  name: 'Home',
}
</script>

<style lang="scss" scopped>
  @import '../sass/animation.scss';
  @import '../sass/button.scss';

  .home {
    & .intro {
      height: calc(100vh - 60px);
      position: relative;
      display: flex;
      align-items: center;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      background-image: url('../assets/home.png');
      padding: 0 1rem 1rem 1rem;

      @media only screen and (min-width: 1200px) {
        & .label { 
          margin: 0 0 200px 30px;
        }
      }

      & .label {
        box-shadow: 0 3px 45px #00546a9d;
        padding: 60px 50px;
        max-width: 610px;
        color: #FFF;
        background-color: var(--primary);
        border-radius: 15px;

        & h1 {
              line-height: 2.5rem;
              font-size: 1.5rem;
              font-weight: 800;

        }

        & p {
          margin: 15px 0 30px 0;
          line-height: 1.875rem;
          font-size: 1.5rem;
        }

        & span {
          color: var(--accent);
        }
        
      }
    }

    .benefits {
      background-color: var(--accent);
      color: #FFF;
      padding: 1.5rem;
      display: flex;

      @media only screen and (min-width: 950px) {
        justify-content: flex-end;
        padding: 3rem;
        
        & .label {
          padding: 3rem;
          max-width: 600px;
        }

         & ul {
          margin: 40px 0px 50px 30px;
          list-style: url('../assets/list_style.png');
        }
      }

      & ul {
        margin: 40px 0px 50px 30px;
        list-style: url('../assets/list_style.png');

        & li {
          margin-bottom: 15px;
        }
      }
    }
  }
</style>
