<template>
    <div id="order">
        <div class="order--state">
            <div class="message">
                <div class="loader" v-bind:class="{ success: etatreservation }"></div>
                <h6 class="message--label">{{ reservationencours ? 'RESERVATION EN COURS ...' : message }}</h6>
            </div>
            <!--<div class="etat--info" v-if="etatMachineOrdre.occuper == false">
                <div class="etat--section">
                    <div class="etat--color" v-bind:class="{ success: etatMachineOrdre.hublot_close }"></div>
                    <span>Fermeture du hublot</span>
                </div>
                <div class="etat--section">
                    <div class="etat--color" v-bind:class="{ success: etatMachineOrdre.program_selected }"></div> 
                    <span>Choix du programme sur la machine</span>
                </div>
            </div>-->
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import {ConfigVars} from "../config-app";
import {Etats} from "../config-app";

export default {
    name: 'OrderReservation',
    props: {
        machineOrder: null,
        id: String,
    },
    data: function() {
        return {
            message: '',
            reservationencours:true,
            etatreservation:false,
            interval: null,
        }
    },
    mounted: function() {
        this.getInfo();
    },
    unmounted: function() {
        clearInterval(this.interval);
    },
    methods: {
        getInfo() {
            this.interval = setInterval(() => {
                this.getMachineStatut()
            }, 2000);
        },

        stopgetInfo() {
            clearInterval(this.interval);
        },

        getMachineStatut: function() {
            axios.get(ConfigVars.URL_API  + 'machines/' + this.id + '/ordre', {params: {deo_id: this.machineOrder}})
            .then((response) => {
                console.log('success get ordre reserver', response)
                
                switch ( Number(response.data.data.ordre.deo_etat) )
                {
                    case Etats.ETAT_TERMINE: 
                    case Etats.ETAT_VALIDE: 
                        this.reservationencours=false;
                        this.etatreservation=true;
                        this.stopgetInfo();
                        break;
                    case Etats.ETAT_ERREUR:
                        this.reservationencours=false;
                        this.stopgetInfo();
                        break;
                }

                this.message = response.data.data.ordre.message;
            })
            .catch((response) => {
                console.log('error get ordre reserver',response);
            });  
        },
    }
}   
</script>

<style scoped>

.message {
    display: flex;
    align-items: center;
    
}

.message--label {
    margin-left: 10px;
}

.etat--section {
    display: flex;
    align-items: center;
}
.order--state {
    background-color: #FFF;
    padding: 2rem;
    display:flex;
    flex-direction: column;
    align-items: center;

    color: var(--primary);

    border-radius: 20px;
}

.etat--info {
    margin-top: 32px;
    height: 100px;
}


.etat--section {
    margin-bottom: 32px;
}


.etat--section span {
    margin-left: 8px;
}

.etat--color {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: red;
}

.etat--color.success {
    background-color: lawngreen;
}


.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid var(--primary); /* Blue */
  border-radius: 50%;
  width: 32px;
  height: 32px;
  animation: spin 2s linear infinite;
}

.loader.success {
    animation:  none;
    background-color: lawngreen;
    border: none;
    
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>