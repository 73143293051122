<template>
  <div id="concept">
    <h1>Concept</h1> 
    <router-link to="/">
      <button class="btn-proxi btn-primary btn-orange">
        Choisir la laverie
      </button>
    </router-link>
    <router-link to="/">
      <button class="btn-proxi btn-primary btn-bleu">
        Choisir la laverie
      </button>
    </router-link>
    <router-link to="/">
      <button class="btn-proxi btn-secondary btn-orange">
        Choisir la laverie
      </button>
    </router-link>
    <div class="test">
      <router-link to="/">
        <button class="btn-proxi btn-secondary btn-white">
          3 &euro;<i></i>SELECTIONNER
        </button>
      </router-link>
      <router-link to="/">
        <button class="btn-proxi btn-typemachine btn-lavl ">
          Lave-linge
        </button>
      </router-link>
      <router-link to="/">
        <button class="btn-proxi btn-typemachine btn-sechl">
          Sèche-linge
        </button>
      </router-link>
    </div>
    <div class="test2">
      <router-link to="/">
        <button class="btn-proxi btn-selection btn-scanqr">
          Scanner un code QR
        </button>
      </router-link>
      <router-link to="/">
        <button class="btn-proxi btn-selection btn-liste">
          Liste des machines
        </button>
      </router-link>
    </div>
    
  </div>
</template>

<script>

export default {
  name: 'Concept',
  components: {
  
  },
  
}
</script>

<style scoped lang="scss">

@import '../sass/button.scss';

.test {
  height: 200px;
  background-color: var(--background);
}

.test2 {
  height: 200px;
  background-color: var(--primary);
}
</style>
