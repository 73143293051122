<template>
  <div id="machine" class="machine">
      <!--
    <MachineStatus id="300003000306832" class="child1"/>
    <MachineStatus id="300003000306832" class="child2"/>
    <MachineStatus id="300003000306832" class="child3"/>
    -->
  </div>
</template>

<script>
// import MachineStatus from '../components/MachineStatus.vue';

export default {
    name: 'MachineTest',
    components: {
        //MachineStatus,
    },
    props: {
        id: String,
    },
}
</script>

<style scoped>
    .machine {
        background-color: var(--background);
        padding: 1rem;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
</style>