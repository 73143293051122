<template>
    <div id="order">
        <div class="order--state">
            <div class="message">
                <div class="loader" v-bind:class="{ success: etatMachineOrdre.occuper }"></div>
                <h6 class="message--label">{{ message }}</h6>
            </div>
            <div class="etat--info" v-if="etatMachineOrdre.occuper == false">
                <div class="etat--section">
                    <div class="etat--color" v-bind:class="{ success: etatMachineOrdre.hublot_close }"></div>
                    <span>Fermeture du hublot</span>
                </div>
                <div class="etat--section">
                    <div class="etat--color" v-bind:class="{ success: etatMachineOrdre.program_selected }"></div> 
                    <span>Choix du programme sur la machine</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import {ConfigVars, Etats} from "../config-app";

export default {
    name: 'Order',
    props: {
        machineOrder: null,
        id: String,
    },
    data: function() {
        return {
            message: '',
            etatMachineOrdre: {
                hublot_close: false,
                program_selected: false,
                occuper: false,
            },
            interval: null,
        }
    },
    mounted: function() {
        this.getInfo();
    },
    unmounted: function() {
        clearInterval(this.interval);
    },
    methods: {
        getInfo() {
            this.interval = setInterval(() => {
                this.getMachineStatut()
            }, 2000);
        },
        stopgetInfo() {
            clearInterval(this.interval);
        },
        getMachineStatut: function() {
            axios.get(ConfigVars.URL_API  + 'machines/' + this.id + '/ordre', {params: {deo_id: this.machineOrder}})
            .then((response) => {
                console.log('success', response)
                var etatVerif = response.data.data.ordre.etapes[0].etat;
                var etatlogin = response.data.data.ordre.etapes[1].etat;
                var etatReglement = response.data.data.ordre.etapes[2].etat;
                var etatDemarrage = response.data.data.ordre.etapes[3].etat;
                var hulbotFerme = response.data.data.ordre.etapes[3].etat_machine.hublot_ferme;
                var program_selected = response.data.data.ordre.etapes[3].etat_machine.prog_selected;

                /*
                00001000001102  VALIDE
                100001000001037  ATTENTEVALIDATION
                100001000001009 OCCUPE
                100001000001001 COURANT ( ordre envoyé sur le serveur )
                100001000001015 ACTIVITE ( ordre acquitté par la CPI / en cours de traitement )
                300003000050001 ERREUR ( retour = erreur )
                100001000001014 TERMINE ( ordre terminé )
                100001000001016 ANNULATION ( ordre annulé par le tdb par ex )
                */

                //const valide = '100001000001102';
                //const attentevalidation = '100001000001037';
                //const erreur = '300003000050001';
                //const occuper = '100001000001009';
                // const annulation = '100001000001016';
                //const courant = '100001000001001';
                // const activite = '100001000001015';
                // const terminer = '100001000001014';

                switch(Number(etatVerif)) {
                    case Etats.ETAT_ERREUR: 
                        this.message = 'Erreur validation !';
                    break;

                    case Etats.ETAT_ATTENTEVALIDATION: 
                        this.message = 'En attente que la CPI recoit et acquitte ordre';
                    break;

                    case Etats.ETAT_VALIDE:
                        this.message = 'Validation OK';
                        switch(Number(etatlogin)) {
                            case Etats.ETAT_ERREUR: 
                                this.message = 'Erreur login !';
                            break;

                            case Etats.ETAT_VALIDE: 
                                this.message = 'Login OK';

                                switch(Number(etatlogin)) {
                                    case Etats.ETAT_ERREUR: 
                                        this.message = 'Erreur login !';
                                    break;

                                    case Etats.ETAT_VALIDE: 
                                        this.message = 'Login OK';

                                        switch(Number(etatReglement)) {
                                            case Etats.ETAT_ERREUR: 
                                                this.message = 'Erreur règlement !';
                                            break;

                                            case Etats.ETAT_VALIDE: 
                                                this.message = 'Reglement OK';
                                                
                                                switch(Number(etatDemarrage)) {
                                                    case Etats.ETAT_ERREUR: 
                                                        this.message = 'Merci de vérifier :';

                                                        if(hulbotFerme == true) {
                                                            this.etatMachineOrdre.hublot_close = true;
                                                        }else {
                                                            this.etatMachineOrdre.hublot_close = false;
                                                        }

                                                        if(program_selected == true) {
                                                            this.etatMachineOrdre.program_selected = true;
                                                        }else {
                                                            this.etatMachineOrdre.program_selected = false;
                                                        }
                                                    break;

                                                    case Etats.ETAT_OCCUPE: 
                                                        this.message = 'Démarrage de la machine réussi';

                                                        this.$store.dispatch('getUser', 
                                                            this.$store.state.user.data.attributs.login,
                                                        )

                                                        this.$emit('machineOccupe');
                                                        
                                                    break;

                                                    case Etats.ETAT_COURANT: 
                                                        this.message = 'Appuyer sur le bouton start';
                                                   
                                                        if(hulbotFerme == true) {
                                                            this.etatMachineOrdre.hublot_close = true;
                                                        }else {
                                                            this.etatMachineOrdre.hublot_close = false;
                                                        }

                                                        if(program_selected == true) {
                                                            this.etatMachineOrdre.program_selected = true;
                                                        }else {
                                                            this.etatMachineOrdre.program_selected = false;
                                                        }
                                                    break; 
                                                }

                                            break;
                                        }
                                    break;
                                }
                            break;
                        }
                    break;
                }
            })
            .catch((response) => {
                console.log('error',response);
            });  
        },
    }
}   
</script>

<style scoped>

.message {
    display: flex;
    align-items: center;
    
}

.message--label {
    margin-left: 10px;
}

.etat--section {
    display: flex;
    align-items: center;
}
.order--state {
    background-color: #FFF;
    padding: 2rem;
    display:flex;
    flex-direction: column;
    align-items: center;

    color: var(--primary);

    border-radius: 20px;
}

.etat--info {
    margin-top: 32px;
    height: 100px;
}


.etat--section {
    margin-bottom: 32px;
}


.etat--section span {
    margin-left: 8px;
}

.etat--color {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: red;
}

.etat--color.success {
    background-color: lawngreen;
}


.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid var(--primary); /* Blue */
  border-radius: 50%;
  width: 32px;
  height: 32px;
  animation: spin 2s linear infinite;
}

.loader.success {
    animation:  none;
    background-color: lawngreen;
    border: none;
    
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>