<template>
    <div id="program">

        <div v-if="(infoReservation.length == 0 || infoReservation.id <= 1 ) && !clicSurReserver">
           <button v-bind:class="this.typemachineDefaut == '2' ? 'btn-proxi-lavage' : 'btn-proxi-sechage'" @click="clicSurReserver=true;">RESERVER</button>
        </div>
        <div v-if="(infoReservation.length == 0 || infoReservation.id <= 1 ) && clicSurReserver">
           <button v-bind:class="this.typemachineDefaut == '2' ? 'btn-proxi-lavage' : 'btn-proxi-sechage'" @click="$emit('reservationSelected', {reservationSelected: true});">CLIQUEZ POUR CONFIRMER LA RESERVATION</button>
        </div>

        <div v-for="tarif in tarifsMachine" :key="tarif.id">
           <button v-bind:class="this.typemachineDefaut == '2' ? 'btn-proxi-lavage' : 'btn-proxi-sechage'" @click="$emit('programSelected', {ref: tarif.ref_id,ref_nom: tarif.ref_nom, prix: tarif.prixclient,programSelected: true});">{{ tarif.ref_nom }} {{ tarif.prixclient }} &euro;</button>
        </div>

        <!--<div v-if="tarifsMachine.length == 0">
           <button v-bind:class="this.typemachineDefaut == '2' ? 'btn-proxi-lavage' : 'btn-proxi-sechage'" >PAS DE SELECTION TROUVEE SUR LA MACHINE</button>
        </div>
        -->
        
        <!--<div v-if="this.messageprog">
           <button v-bind:class="this.typemachineDefaut == '2' ? 'btn-proxi-lavage' : 'btn-proxi-sechage'" >{{ messageprog }}</button>
        </div>-->

        <div v-for="msg in messagesprog" :key="msg">
           <button v-bind:class="this.typemachineDefaut == '2' ? 'btn-proxi-lavage' : 'btn-proxi-sechage'" >{{ msg }}</button>
        </div>

    </div>
</template>

<script>
export default {
    name: 'Program',
    props: {
        typemachineDefaut : {
            type: String,
            default: '2'
        },
        tarifsMachine: {},
        infoReservation: {},
        messagesprog: {
            type: Array
        },
    },
    data: function () {
        return {
            clicSurReserver: false,
        }
    },
    mounted: function() {

        console.log('Program mounted');
    }
}   
</script>

<style scoped lang="scss">
.btn-proxi-lavage {
    width: 100%;
    padding: 2rem;
    margin-bottom: 32px;
    color: var(--primary);

    background-color: #FFF;

    border-radius: 10px;

    &:hover {
        background-color: var(--accent);
        color: #FFF;
    }
}
.btn-proxi-sechage {
    width: 100%;
    padding: 2rem;
    margin-bottom: 32px;
    color: var(--primary);

    background-color: #FFF;

    border-radius: 10px;

    &:hover {
        background-color: var(--background);
        color: #FFF;
    }
}
</style>