import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Machine from '../views/Machine.vue'
import MachineTest from '../views/MachineStartTest.vue'
import Conseils from '../views/Conseils.vue'
import Concept from '../views/Concept.vue'
import Scanner from '../views/Scanner.vue'
import Contact from '../views/Contact.vue'
import CreeUnCompte from '../views/CreerCompte.vue'
import Credit from '../views/Credit.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/conseils',
    name: 'Conseils',
    component: Conseils
  },
  {
    path: '/concept',
    name: 'Concept',
    component: Concept
  },
  {
    path: '/scanner',
    name: 'Scanner',
    component: Scanner
  },
  {
    path: '/concept',
    name: 'Concept',
    component: Concept
  },
  {
    path: '/creeuncompte',
    name: 'CreeUnCompte',
    component: CreeUnCompte
  },
  {
    path: '/credit',
    name: 'Credit',
    component: Credit
  },
  {
    path: '/login/:machineid?',
    name: 'Login',
    props: true,
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  }
  ,
  {
    path: '/profile',
    name: 'Profile',
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Profile.vue')
  },
  {
    path: '/weblaverie',
    name: 'Weblaverie',
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Weblaverie.vue')
  },
  {
    path: '/laverie/:code/:listMachineSelectedDefaut?/:listMachineAfficherLaveLingeDefaut?',
    name: 'Laverie',
    props: true,
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Laverie.vue')
  },
  {
    path: '/retour',
    name: 'Retour',
    props: route => ({ query: route.query.tid }),
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Retour.vue')
  },
  {
    path: '/retourpaymentcb',
    name: 'RetourPaymentCB',
    props: route => ({ query: route.query.tid }),
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/RetourPaymentCB.vue')
  },
  {
    path: '/form',
    name: 'FormMercanet',
    props: true,
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/FormMercanet.vue')
  },
  {
    path: '/fiche/:id',
    name: 'Fiche',
    props: true,
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Fiche.vue')
  },
  {
    path: '/machine/:id?',
    //redirect: '/machine/:id',
    name: 'Machine',
    props: true,
    //props: { modepaiements: [], paiementsData: [], paiementsDataStr:'' },
    // which is lazy-loaded when the route is visited.
    component: Machine
  },
  {
    path: '/machinetest/:id',
    name: 'MachineTest',
    props: true,
    // which is lazy-loaded when the route is visited.
    component: MachineTest
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
